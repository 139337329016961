import { EntityModel } from "hateoas-hal-types";
import { useEffect, useState } from "react";
import { Accordion, Badge } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useDeviceSelectors } from "react-device-detect";
import { FaAngleDown, FaAngleUp, FaArrowCircleRight } from "react-icons/fa";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router";
import { useSWRConfig } from "swr";
import { InlineLinkButton } from "../../components/Button/button";
import EditorInfo from "../../components/EditorInfo";
import LabelValue from "../../components/label-value";
import Skeleton from "../../components/Skeleton";
import { useHighlightContext } from "../../contexts/highlight.context";
import { useLoadingProgress } from "../../contexts/loading-progress.context";
import { useSelectedActContext } from "../../contexts/selected-act.context";
import AllowedByCourt from "../../models/AllowedByCourt.enum";
import { JudgementAct, JudgementActDomain } from "../../models/JudgementAct.model";
import { JudgementActLinkType } from "../../models/JudgementActLink.model";
import { UserDocumentAnno } from "../../models/UserDocumentAnno.model";
import { shortenUrl } from "../../services/url-services";
import { CollapsibleFiltersPanel } from "../SearchPage/FiltersSidebar/filters";
import ProvisionsCard from "../SummaryViewPage/provisions-card";

type JudgementActMetadataProps = {
  act: EntityModel<JudgementAct>;
  annotations?: RecogitoAnno[];
};

const JudgementActMetadata: React.FC<JudgementActMetadataProps> = ({ act, annotations }) => (
  <>
    {(act.domain === JudgementActDomain.DOMESTIC ||
      act.domain === JudgementActDomain.ADMINISTRATIVE ||
      act.domain === JudgementActDomain.CONSTITUTIONAL ||
      act.domain === JudgementActDomain.SUPREME_COURT) && (
      <Row className="info-cards-row">
        <Col md={5}>
          <ExtractedInfoCard act={act} />
        </Col>
        <Col md={7}>
          <MainInfoCard act={act} />
        </Col>
      </Row>
    )}
    {(!!act.summaries?.[0]?.provisions.length || !!act.provisions.length) && (
      <Row className="info-cards-row mt-3">
        <Col md={12}>
          <ProvisionsCard
            provisions={[...(act.provisions || []), ...(act.summaries?.[0]?.provisions || [])]}
            compact
          />
        </Col>
      </Row>
    )}
    <OperativeCard act={act} annotations={annotations} />
  </>
);

const ExtractedInfoCard: React.FC<JudgementActMetadataProps> = ({ act }) => {
  const intl = useIntl();
  const { setSelectedActId } = useSelectedActContext();
  return (
    <Card className="info-card">
      <Card.Body>
        {act.caseType &&
          act.caseType.code !== "CRIMINAL" &&
          act.caseType.code !== "CRIMINAL_PRIVATE" && (
            <LabelValue label={intl.formatMessage({ id: "view-judgement.labels.proceedingBy" })}>
              {act.proceeding.name}
            </LabelValue>
          )}

        <LabelValue
          show={!!act?.caseAdminBody}
          label={intl.formatMessage({ id: "view-judgement.labels.adminbodyVas" })}
        >
          <AdmBodyLink admBody={act?.caseAdminBody} />
        </LabelValue>
        <LabelValue
          show={!!act?.caseMatter}
          label={intl.formatMessage({ id: "view-judgement.labels.matterVas" })}
        >
          <MatterLink matter={act.caseMatter} />
        </LabelValue>

        <LabelValue
          label={intl.formatMessage({ id: "view-judgement.labels.isAllowedByCourt" })}
          show={
            act.allowedByCourt === AllowedByCourt.ALLOWED ||
            act.allowedByCourt === AllowedByCourt.DISALLOWED
          }
        >
          {act.allowedByCourt === AllowedByCourt.ALLOWED ? "допуска" : "не допуска"}
        </LabelValue>
        <LabelValue label={intl.formatMessage({ id: "view-judgement.labels.dissentingOpinion" })}>
          {act.hasDissentingOpinion ? (
            <Badge bg="warning" style={{ fontSize: "0.8rem" }}>
              <FormattedMessage id="view-judgement.labels.dissentingOpinion.yes" />
            </Badge>
          ) : (
            <FormattedMessage id="view-judgement.labels.dissentingOpinion.no" />
          )}
        </LabelValue>
        <LabelValue
          label={intl.formatMessage({ id: "view-judgement.labels.annotatedBy" })}
          show={!!act?.annotatedBy}
        >
          <EditorInfo editor={act?.annotatedBy} />
        </LabelValue>
        <LabelValue
          label={intl.formatMessage({ id: "view-judgement.labels.highlightedBy" })}
          show={!!act?.highlightedBy?.length}
        >
          {act?.highlightedBy?.map((editor) => (
            <EditorInfo editor={editor} />
          ))}
        </LabelValue>
        <LabelValue
          label={intl.formatMessage({ id: "view-judgement.labels.risk" })}
          show={!!act?.contradictingActs.length || !!act?.interpretiveActs.length}
        >
          {!!act?.contradictingActs.length && (
            <>
              <Badge bg="danger" style={{ fontSize: "0.8rem" }}>
                <FormattedMessage id="view-judgement.labels.contradictictionWithLaterAct" />
              </Badge>
              {act?.contradictingActs.map((act) => (
                <InlineLinkButton
                  onClick={() => setSelectedActId(act.id)}
                  style={{ texAlign: "left" }}
                >
                  {act.title}
                </InlineLinkButton>
              ))}
            </>
          )}
          {!!act?.interpretiveActs.length && (
            <>
              <Badge bg="warning" style={{ fontSize: "0.8rem" }}>
                <FormattedMessage id="view-judgement.labels.citedInInterpretiveCases" />
              </Badge>{" "}
              {act?.interpretiveActs.map((act) => (
                <InlineLinkButton
                  onClick={() => setSelectedActId(act.id)}
                  style={{ texAlign: "left" }}
                >
                  {act.title}
                </InlineLinkButton>
              ))}
            </>
          )}
        </LabelValue>
        <LabelValue
          label={intl.formatMessage({ id: "view-judgement.labels.result" })}
          show={act?.proceeding.code === "чл. 292 ГПК"}
        >
          {act?.referencesTo.find((ref) => ref.type === JudgementActLinkType.RULES) ? (
            <>
              <Badge bg="success" style={{ fontSize: "0.8rem" }}>
                <FormattedMessage id="view-judgement.labels.ruled" />
              </Badge>
              {act?.referencesTo
                .filter((ref) => ref.type === JudgementActLinkType.RULES)
                .map((link) => (
                  <InlineLinkButton
                    onClick={() => setSelectedActId(link.act.id)}
                    style={{ texAlign: "left" }}
                  >
                    {link.act.title}
                  </InlineLinkButton>
                ))}
            </>
          ) : (
            <Badge bg="warning" style={{ fontSize: "0.8rem" }}>
              <FormattedMessage id="view-judgement.labels.awaits-ruling" />
            </Badge>
          )}
        </LabelValue>
      </Card.Body>
    </Card>
  );
};

const AdmBodyLink: React.FC<{ admBody: string }> = ({ admBody }) => {
  const navigate = useNavigate();
  return (
    <InlineLinkButton
      style={{ verticalAlign: "baseline" }}
      onClick={async () => {
        const params = new URLSearchParams();
        params.append("caseAdminBody", admBody);
        const q = await shortenUrl(params.toString());
        navigate(`/search/adm-acts?q=${q}`);
      }}
    >
      {admBody}
    </InlineLinkButton>
  );
};

const MatterLink: React.FC<{ matter: string }> = ({ matter }) => {
  const navigate = useNavigate();
  return (
    <InlineLinkButton
      style={{ verticalAlign: "baseline" }}
      onClick={async () => {
        const params = new URLSearchParams();
        params.append("caseMatter", matter);
        const q = await shortenUrl(params.toString());
        navigate(`/search/adm-acts?q=${q}`);
      }}
    >
      {matter}
    </InlineLinkButton>
  );
};

export const QuickViewInfoCard: React.FC<JudgementActMetadataProps> = ({ act }) => {
  const intl = useIntl();
  const { setSelectedActId } = useSelectedActContext();

  return (
    <Card className="info-card">
      <Card.Body>
        {act.caseType &&
          act.caseType.code !== "CRIMINAL" &&
          act.caseType.code !== "CRIMINAL_PRIVATE" && (
            <LabelValue label={intl.formatMessage({ id: "view-judgement.labels.proceedingBy" })}>
              {act.proceeding.name}
            </LabelValue>
          )}
        <LabelValue
          label={intl.formatMessage({ id: "view-judgement.labels.reporter" })}
          show={!!act.reporters && !!act.reporters?.length}
        >
          {act.reporters?.join(", ")}
        </LabelValue>
        <LabelValue
          label={
            <Badge bg="success" style={{ fontSize: "0.8rem" }}>
              {intl.formatMessage({ id: "view-judgement.labels.summary" })}:
            </Badge>
          }
          show={!!act.summaries?.length}
        >
          <InlineLinkButton onClick={() => setSelectedActId("summary:" + act.summaries[0]?.id)}>
            {act.summaries[0]?.shortTitle}
          </InlineLinkButton>
        </LabelValue>
        <LabelValue
          label={intl.formatMessage({ id: "view-judgement.labels.reporter" })}
          show={!!act.reporters && !!act.reporters?.length}
        >
          {act.reporters?.join(", ")}
        </LabelValue>
        <LabelValue
          label={intl.formatMessage({ id: "view-judgement.labels.source" })}
          show={act.domain === JudgementActDomain.CONSTITUTIONAL && !!act.caseSrc}
        >
          <a target="_blank" href={act.caseSrc} className="link inline-link">
            Страница на КС
          </a>
        </LabelValue>
      </Card.Body>
    </Card>
  );
};

export const ExtractedInfoCardLoading = () => (
  <Card className="info-card">
    <Card.Body>
      <Skeleton count={4} />
    </Card.Body>
  </Card>
);

export const OperativeCard: React.FC<JudgementActMetadataProps> = ({ act, annotations }) => {
  const [metaDetailToggle, setMetaDetailToggle] = useState("0");
  const hasAnnotations = annotations?.length;

  useEffect(() => {
    !hasAnnotations && setMetaDetailToggle("0");
  }, [hasAnnotations, setMetaDetailToggle]);

  const { includeEditorAnnotations } = useHighlightContext();

  return act.operativePart || act.hasEditorAnnotation ? (
    <Card className="highlight-card">
      <Card.Body
        onClick={() => hasAnnotations && setMetaDetailToggle(metaDetailToggle === "1" ? "0" : "1")}
      >
        <Card.Text className="search-target" style={{ cursor: hasAnnotations ? "" : "auto" }}>
          {act.operativePart && (
            <div
              dangerouslySetInnerHTML={{
                __html: act.operativePart.replace(
                  /^<p>([а-я]+:)/iu,
                  '<p><span class="fw-500">$1</span>'
                ),
              }}
              className="act-text card-subtitle"
            />
          )}

          {act.hasEditorAnnotation && (
            <>
              <div className={`act-text card-subtitle mb-3 ${act.operativePart ? "mt-3" : ""}`}>
                {act.operativePart?.trim() && (
                  <p>
                    <span className="fw-500">АНОТАЦИЯ:</span>
                  </p>
                )}
              </div>
              <div
                dangerouslySetInnerHTML={{
                  __html: act.editorAnnotation,
                }}
                className="act-text card-subtitle"
              />
            </>
          )}

          {hasAnnotations ? (
            metaDetailToggle === "1" ? (
              <FaAngleUp className="toggle-indicator question-info-icon" />
            ) : (
              <FaAngleDown className="toggle-indicator question-info-icon" />
            )
          ) : null}
        </Card.Text>
      </Card.Body>
      <Accordion
        activeKey={metaDetailToggle}
        className={metaDetailToggle === "1" ? "" : "search-exclude"}
      >
        <Accordion.Collapse eventKey="1">
          <Card.Header>
            <div>
              {annotations?.map((anno) => (
                <LabelValue
                  key={anno.id}
                  label={anno.body.find((body) => body.purpose === "tagging")!.value}
                  className="annotated"
                >
                  <p className="d-inline">
                    {anno.target.selector
                      .filter((selector) => !!selector.exact)!
                      .map((selector) => {
                        const isArguments =
                          anno.body.find((body) => body.purpose === "tagging")!.value === "Мотиви";
                        const isQuestion =
                          anno.body.find((body) => body.purpose === "tagging")!.value === "Въпрос";
                        let text =
                          isArguments && selector.exact.length > 1200
                            ? selector.exact.substring(0, 1000) + "..."
                            : selector.exact;
                        if (isQuestion) {
                          text = text.replace(/^въпрос(а|ът|ите|-)?,?\s*/i, "");
                        }
                        text = text.charAt(0).toUpperCase() + text.slice(1);
                        return isQuestion
                          ? text.endsWith(".")
                            ? text.replace(/\.$/, "?")
                            : text.endsWith("?")
                            ? text
                            : text + "?"
                          : !text.endsWith(".")
                          ? text + "."
                          : text;
                      })}{" "}
                  </p>
                  <InlineLinkButton
                    onClick={() => {
                      scrollAndHighlight(
                        anno.id,
                        includeEditorAnnotations ? "rgb(247, 249, 255)" : undefined
                      );
                    }}
                    title="Намери в документа"
                  >
                    <FaArrowCircleRight />
                  </InlineLinkButton>
                </LabelValue>
              ))}
            </div>
          </Card.Header>
        </Accordion.Collapse>
      </Accordion>
    </Card>
  ) : null;
};

export const QuickViewOperativeCard: React.FC<JudgementActMetadataProps> = ({ act }) => {
  return act.operativePart || act.hasEditorAnnotation ? (
    <Card className="highlight-card">
      <Card.Body>
        <CollapsibleFiltersPanel
          collapseHeight="100px"
          openedLabel="Покажи по-малко"
          closedLabel="Покажи всичко"
        >
          <Card.Text className="search-target">
            {act.operativePart && (
              <div
                dangerouslySetInnerHTML={{
                  __html: act.operativePart,
                }}
                className="act-text card-subtitle"
              />
            )}

            {act.hasEditorAnnotation && (
              <>
                <div className={`act-text card-subtitle mb-3 ${act.operativePart ? "mt-3" : ""}`}>
                  {act.operativePart?.trim() && <p>АНОТАЦИЯ:</p>}
                </div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: act.editorAnnotation,
                  }}
                  className="act-text card-subtitle"
                />
              </>
            )}
          </Card.Text>
        </CollapsibleFiltersPanel>
      </Card.Body>
    </Card>
  ) : null;
};

export type RecogitoAnno = {
  id: string;
  body: { type: string; purpose: string; value: string }[];
  target: { selector: { type: string; exact: string; start: number }[] };
};
export const filterAndSortTocAnnotations = (annotations?: UserDocumentAnno[]) =>
  [...(annotations || [])]
    ?.map((anno) => JSON.parse(anno.body) as RecogitoAnno)
    .filter(
      (anno) =>
        anno.body.some((body) => body.value === "PUBLIC") &&
        anno.body.some(
          (body) =>
            body.value === "Въпрос" ||
            body.value === "Отговор" ||
            body.value === "Мотиви" ||
            body.value === "Основание за допускане" ||
            body.value === "По същество"
        )
    )
    .sort(
      (a1, a2) =>
        a1.target.selector.find((selector) => typeof selector.start !== "undefined")!.start -
        a2.target.selector.find((selector) => typeof selector.start !== "undefined")!.start
    );

export const filterPersonalMarkers = (annotations?: UserDocumentAnno[]) =>
  [...(annotations || [])]
    ?.map((anno) => JSON.parse(anno.body) as RecogitoAnno)
    .filter((anno) => anno.body.some((body) => body.value === "PRIVATE"))
    .sort(
      (a1, a2) =>
        a1.target.selector.find((selector) => typeof selector.start !== "undefined")!.start -
        a2.target.selector.find((selector) => typeof selector.start !== "undefined")!.start
    );

export const scrollToTargetAdjusted = (element: HTMLElement) => {
  var headerOffset = 150;
  var elementPosition = element.getBoundingClientRect().top;
  var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

  window.scrollTo({
    top: offsetPosition,
  });
};

export const scrollToTargetAdjustedInsideParent = (element: HTMLElement) => {
  var headerOffset = 150;
  var elementPosition = element.offsetTop;
  var offsetPosition = elementPosition - headerOffset;
  element.parentElement!.scrollTop = offsetPosition;
};

export const scrollAndHighlight = (dataId: string, color?: string) => {
  const scrollTo = document.querySelectorAll(`*[data-id='${dataId}']`)!;
  scrollToTargetAdjusted(scrollTo[0] as HTMLElement);

  scrollTo.forEach((e) => {
    const htmlE = e as HTMLElement;
    htmlE.style.backgroundColor = "#feffb3";
    setTimeout(() => {
      htmlE.style.transition = "background-color 1500ms linear";
      htmlE.style.backgroundColor = color || "";
      setTimeout(() => {
        htmlE.style.transition = "";
      }, 2000);
    }, 4000);
  });
};

const MainInfoCard: React.FC<JudgementActMetadataProps> = ({ act }) => {
  const [{ isMobileOnly }] = useDeviceSelectors(window.navigator.userAgent);
  const progress = useLoadingProgress();
  const { cache } = useSWRConfig();
  const navigate = useNavigate();

  const { setSelectedActId } = useSelectedActContext();

  const intl = useIntl();
  return (
    <Card className="info-card">
      <Card.Body>
        <LabelValue label={intl.formatMessage({ id: "view-judgement.labels.actType" })}>
          {act.actType.name}
        </LabelValue>
        <LabelValue
          label={intl.formatMessage({ id: "view-judgement.labels.caseType" })}
          show={!!act.caseNumber && !!act.caseType}
        >
          {act.caseType?.name}
        </LabelValue>
        <LabelValue
          label={intl.formatMessage({ id: "view-judgement.labels.reporter" })}
          show={!!act.reporters && !!act.reporters?.length}
        >
          {act.reporters?.join(", ")}
        </LabelValue>
        <LabelValue
          show={!!act?.caseStatus}
          label={intl.formatMessage({ id: "view-judgement.labels.statusVas" })}
        >
          {act.caseStatus?.shortName}
        </LabelValue>
        <LabelValue
          label={intl.formatMessage({ id: "view-judgement.labels.sgDate" })}
          show={!!act?.sgIssueNumber && !!act?.sgYear}
        >
          <FormattedMessage
            id="view-judgement.labels.sgDate.pattern"
            values={{
              sgIssueNumber: act?.sgIssueNumber,
              sgYear: act?.sgYear,
            }}
          />
        </LabelValue>
        <LabelValue
          label={
            <Badge bg="success" style={{ fontSize: "0.8rem" }}>
              {intl.formatMessage({ id: "view-judgement.labels.summary" })}:
            </Badge>
          }
          show={!!act.summaries?.length}
        >
          <InlineLinkButton onClick={() => setSelectedActId("summary:" + act.summaries[0]?.id)}>
            {act.summaries[0]?.shortTitle}
          </InlineLinkButton>
        </LabelValue>
        <LabelValue
          label={intl.formatMessage({ id: "view-judgement.labels.source" })}
          show={act.domain === JudgementActDomain.CONSTITUTIONAL && !!act.caseSrc}
        >
          <a target="_blank" href={act.caseSrc} className="link inline-link">
            Страница на КС
          </a>
        </LabelValue>
      </Card.Body>
    </Card>
  );
};

export const MainInfoCardLoading = () => (
  <Card className="info-card">
    <Card.Body>
      <Skeleton count={4} />
    </Card.Body>
  </Card>
);

export default JudgementActMetadata;
